module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Omar Muscatello","short_name":"OM","lang":"en","start_url":"/en/cv","icon":"src/images/icon.png","cache_busting_mode":"none","display":"standalone","theme_color":"#2e74b5","background_color":"#fff","localize":[{"lang":"it","start_url":"/it/cv/"}],"icon_options":{"purpose":"any"},"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["icons/*","favicon*"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"translations","languages":["it","en"],"defaultLanguage":"en","i18nextOptions":{"keySeparator":"."},"pages":[{"matchPath":"/:lang/cv","getLanguageFromPath":true},{"matchPath":"(.*)","languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-T81CRJ5JV1"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
